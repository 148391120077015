<script lang="ts">
  import { ArrowUpRightIcon } from "lucide-svelte";

  export let href: string;
  export let title: string;
  export let description: string;
  export let image: string;
</script>

<a
  {href}
  class="group relative flex flex-col justify-end rounded-lg overflow-hidden h-[380px]"
  style="background: linear-gradient(to top, #212525, transparent);"
>
  <div
    class="absolute inset-0 bottom-[1px] bg-cover bg-center rounded-lg -z-10"
    style="background-image: url({image})"
  ></div>

  <div class="space-y-5 px-5 py-4 relative">
    <h3 class="marketing-h4 text-light-green">{title}</h3>
    <p class="text-light-green">{description}</p>
    <ArrowUpRightIcon
      size={20}
      class="group-hover:ml-2 transition-[margin] text-light-green"
    />
  </div>
</a>
